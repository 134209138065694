.ManageCmd{
    width: 95%;
    display: flex;
    height: 75vh;
    margin-left: 3%;
}

.ManageCmd div{
    width: 100%;
    font-weight: 600;
}

.ManageCmd .title{
    text-align: center;
    width: 100%;
    font-size: 1.5rem;
    font-weight: 700;
    color: rgb(0, 105, 167)
}

.ManageCmd .div1{
    border-right: solid gray 3px;
}

.ManageCmd .option2{
    text-align: right;
    width: 100%;
}

.modelAdd{
    font-size: 0.8rem;
    font-weight: 700;
    cursor: pointer;
}

.modelAdd:hover{
    font-weight: 600;
    font-style: oblique;
}

.listModel{
    width: 100%;
    padding: 2vh 0 0 3%;
    max-height: 65vh;
    overflow: auto;
}

.listGroup{
    margin: 2vh 0;
    max-height: 65vh;
    overflow: auto;
}

.infoGroup{
    display: flex;
    position: relative;
    cursor: pointer;
    width: 90%;
    align-items: center;
}

.infoGroup:hover{
    background-color: rgba(255, 238, 200, 0.699);
}

.listModel::-webkit-scrollbar {
    width: 6px;
    background-color: #f1efef;
} 
.listModel::-webkit-scrollbar-thumb {
    background-color: #c0b7b7;
}
.listModel::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgb(255, 255, 255);
    background-color: #ffffff;
}

.listModelInGroup::-webkit-scrollbar {
    width: 6px;
    background-color: #f1efef;
} 
.listModelInGroup::-webkit-scrollbar-thumb {
    background-color: #c0b7b7;
}
.listModelInGroup::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgb(255, 255, 255);
    background-color: #ffffff;
}