.home{
    background-color: rgb(185, 185, 185);
    height: 100vh;
}

.headerHome{
    /* height: 14vh; */
    background-color: rgb(255, 255, 255);
}

.bodyHome{
    display: flex;
}


.viewHome{
    height: 93vh;
    width: 100%;
    background: white;
    /* margin: 0.3vh 0 0 0; */
    /* padding: 2vh 0 0 0; */
}

.titleContent{
    font-size: 2rem;
    text-align: center;
    font-weight: 600;
    margin-bottom: 2vh;
    color: rgb(0 124 196)
}