.autoTest{
    width: 100%;
    height: 92vh;
    background-color: white;
}

.autoTest-divAllStation{
    width: 100%;
    display: flex;
}

#autoTest-divAllStation-station{
    width: 10%;
    margin: 10px;
    font-weight: 750;
}

#autoTest-divAllStation-station .good{
    color: rgb(3, 247, 7);
}

#autoTest-divAllStation-station .faulty{
    color: rgb(255, 191, 0);
}

#autoTest-divAllStation-station .bad{
    color: rgb(255, 0, 0);
}

.autoTest-Divtable-table{
    width: 100%;
    border:solid 1px;
    border-collapse: collapse;
}

.autoTest-Divtable-table tr, th, td{
    border:solid 1px rgb(193, 193, 193);
    /* background-color: aquamarine; */
    border-collapse: collapse;
}

#autoTest-Divtable-table-row-status{
    font-weight: 700;
}