
.divStation{
    display: flex;
    flex-flow: row;
    /* background-color: red;    */
    align-items: center;
     padding-top: 1vh;
    margin-left: 1%; 
}

.info{
    display: flex;
    width: 100%;
}
.divInfoStation{
    display: flex;
    flex-flow: column;
    width: 35%;
    height: 55vh;
    justify-content: center;
    margin-left: 3%;
    margin-top: 1.5%;
    font-size: 0.8rem;
}

#inputStation {
    font-size: 0.75rem;
    height: 1.5vh;
}

.divInfo{
    
    margin: 5px;
    display: flex;
}
.spanInfo{
    width: 35%;
    font-weight: 700;
    color: rgb(0, 105, 167)
}

.divInfoLine{
    width: 65%;
    margin-top: -4vh;
    
}

.divAddLine{
    display: flex;
    font-size: 0.8rem;
}

.infoLineTitle{
    display: flex;
    position: relative;
    margin-bottom: 3vh;
}
.infoLineTitle div{
    width: 20%;
    text-align: center;
    font-weight: 700;
    margin: 0 0 2vh 0;
    color: rgb(0, 105, 167)
}
.infoLine{
    display: flex;
    cursor: pointer;
    width: 100%;
}
.infoLine div{
    width: 25%;
    text-align: center;
    /* border:solid */
    
}

.infoLine:hover{
    background-color: #fae7cf;
}