#listValue::-webkit-scrollbar {
    width: 6px;
    background-color: #ffffff;
} 
#listValue::-webkit-scrollbar-thumb {
    background-color: #989393;
}
#listValue::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgb(255, 255, 255);
    background-color: #ffffff;
}