.divInfoLine{
    width: 99%;
    margin-top: 2vh;
    border:solid 1px rgb(222, 222, 222);
    height: 100%;
}
.infoLineTitleTest {
    display: flex;
    position: relative;
    width: 100% - 10px;
    height: 5.5vh;
    background-color: #b9cfd769;
    align-items: center;
    padding: 0 0 0 0px;
    border: solid 1px #b9cfd769;
}

.infoLineTitleTest div {
    /* width: 20%;
    text-align: center; */
    font-weight: 700;
    font-size:0.8rem;
    /* margin: 0 0 4vh 0; */
    color: rgb(0, 105, 167)
    
} 

.lineDiv{
    height: 5vh;
    align-items: center;
    vertical-align: middle;
    align-content: center;
}
.infoLineTest {
    display: flex;
    /* cursor: pointer; */
    position: relative;
    /* margin: 1vh 0 0 2%; */
    width: 100%;
    height: 100%;
    /* margin-top: 5vh; */
}

.infoLineTest div {
    /* width: 30%; */
    text-align: left;

}

.infoLineTest:hover {
    background-color: #fae7cf;
}

.divInfoStationTest {
    display: flex;
    flex-flow: column;
    width: 35%;
    height: 100%;
    /* justify-content: center; */
    margin-left: 1%;
}

#report::-webkit-scrollbar {
    width: 6px;
    background-color: #ffffff;
} 
#report::-webkit-scrollbar-thumb {
    background-color: #989393;
}
#report::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgb(255, 255, 255);
    background-color: #ffffff;
}

#showReport::-webkit-scrollbar {
    width: 6px;
    background-color: #ffffff;
} 
#showReport::-webkit-scrollbar-thumb {
    background-color: #989393;
}
#showReport::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgb(255, 255, 255);
    background-color: #ffffff;
}

@keyframes notiItem {
    0%   {background-color:#93ff00;}
    25%  {background-color:rgba(255, 255, 255, 0);}
    50%  {background-color:#93ff00; }
    75%  {background-color:rgba(255, 255, 255, 0); }
    100% {background-color:#93ff00; }
  }

  @keyframes status-faulty {
    0%   {background-color:#ffd900; color: rgb(124, 124, 124);}
    25%  {background-color:rgba(255, 255, 255, 0);color: orange}
    50%  {background-color:#ffd900; color: rgb(124, 124, 124)}
    75%  {background-color:rgba(255, 255, 255, 0); color: orange}
    100% {background-color:#ffd900; color: rgb(124, 124, 124)}
  }

  @keyframes V00 {
    0%   {background-color:#ff3300; color: rgb(255, 255, 255);}
    25%  {background-color:rgba(255, 255, 255, 0);color: orange}
    50%  {background-color:#ff3300; color: rgb(255, 255, 255);}
    75%  {background-color:rgba(255, 255, 255, 0); color: orange}
    100% {background-color:#ff3300; color: rgb(255, 255, 255);}
  }

  @keyframes note {
    0%   {background-color:#79f6e2;}
    25%  {background-color:rgba(255, 255, 255, 0);}
    50%  {background-color:#79f6e2; }
    75%  {background-color:rgba(255, 255, 255, 0); }
    100% {background-color:#79f6e2; }
  }

  .btnOptionZulip{
        color: black;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
        text-transform: none;
        border: none;
        width: 100%;
        background-color: white;
        height: 4vh;
        cursor: pointer;
        padding: 0px 5px 0 12px;
        display: flex;
        justify-items: center;
        align-items: center;
    }

    .btnOptionZulip:hover{
        background-color: #6b6b6b18;
        border-radius: 3px;
    }