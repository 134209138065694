
.divTitle{
    font-size: 1.5rem;
    margin: 1vh 0;
    font-weight: 600;
    width: 100%;
    color: rgb(0, 105, 167)
}

.iconAdd{
   height: 30;
}

.divList{
    display: flex;
    flex-flow: row;
    /* background-color: red;    */
    align-items: center;
    margin-left: 7%;
}

.divInput{
    display: flex;
    flex-flow: row;
    /* background-color: red;    */
    align-items: center;
    margin-top: 3vh;
    margin-left: 20%;
    width: 70%;
}

.inputModel{
    margin-right: 2%;
    margin-left: 0%;
    /* margin-top: 1vh; */
    display: flex;
    align-items: center;
}

#inputError{
    width: 600px;
}