.headerDiv {
}

.titleHeader {
    display: flex;
    border-bottom: solid rgba(202, 202, 202, 0.856) 2px;
    align-items: center;
    
}

.logoHeader {
    font-size: 1.5rem;
    font-weight: 800;
    color: rgb(0 124 196);
    display: flex;
    align-items: center;

}

.optionHeader {
    /* margin-top: 0.5vh; */
    display: flex;
    position: relative;
    width: 75%;
    justify-content: center;

}

#btnOption {
    margin: 0 0.5%;
}